.label_top {
  background: #fff;
  position: relative;
  top: 10px;
  left: 10px;
  padding: 5px;
  color: #515151;
  font-weight: 500;
}
.input-bor-color {
  border-color: #d5e3ff;
}

.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(200 30 30 / var(--tw-bg-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(125 135 169 / var(--tw-text-opacity));
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.border-transparent {
  border-color: transparent;
}
.bg-blue-600 {
  --tw-border-opacity: 1;
  background-color: rgb(65 71 155 / var(--tw-border-opacity));
}
.card_btn_detail .edit-btn {
  background-color: #d5e3ff;
  color: #41479b;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  border-radius: 1.25rem;
  padding: 0.5rem 1.5rem;
}
.card_btn_detail .delete-btn {
  color: #7d87a9;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  border-radius: 1.25rem;
  background: #d1d5db;
  padding: 0.5rem 1.5rem;
}
.user-name {
  color: #41479b;
  font-size: 1.125rem;
  font-weight: 600;
}
.card-shadow {
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  padding: 15px;
}
.bg-light-red {
  border-radius: 0.375rem;
  background: #ffefe1;
  color: #ff9e43;
  font-size: 1.125rem;
  font-weight: 400;
}
