.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.loadingWrapper {
  min-height: unset;
}

.spinner {
  width: 88px;
  height: 88px;
  display: grid;
  border: 7px solid #000;
  border-radius: 50%;
  border-color: #dbdcef #000;
  animation: spinner-e04l1k 1s infinite linear;
}

.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  margin: 3.5px;
  border: inherit;
  border-radius: 50%;
}

.spinner::before {
  border-color: #e4aa07 #000;
  animation: inherit;
  animation-duration: 0.5s;
  animation-direction: reverse;
}

.spinner::after {
  margin: 14px;
}

@keyframes spinner-e04l1k {
  100% {
    transform: rotate(1turn);
  }
}

@media (max-width: 560px) {
  .spinner {
    width: 64px;
    height: 64px;
  }
}
