.reportpopup {
    position: fixed;
    z-index: 99;
    background: #fff;
    padding: 50px;
    /* max-width: 500px; */
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
}

.popupbackdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    background: #2b2b2b8c;
    top: 0;
    left: 0;
    z-index: 1;

}

input.date-formate {
    box-shadow: none !important;
    border: none !important;

}

/* mediareport */


@media(max-width:650px) {
    .radiobtn {
        display: block !important;
    }

    .rightbtn ul,
    .rightbtn div {
        margin-bottom: 10px;
    }
}

@media(max-width:767px) {
    .reportpopup {
        padding: 20px;
    }
}

@media (max-width:375px) {

    .reportpopup {
        padding: 10px;
    }
}