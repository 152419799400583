.appdw {
  position: absolute;
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 10px 20px #ccc;
  font-size: 16px;
  width: 166px;
  border: 1px solid #ccc;
  transform: translate(1%, 0px);
  right: -7px;
  top: 30px;
}

.appdw:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background: #fff;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  transform: rotate(45deg);
  top: -8px;
  right: 14px;
}

/* video edit dropdown */
.editdw {
  position: absolute;
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 10px 20px #ccc;
  font-size: 16px;
  width: 166px;
  border: 1px solid #ccc;
  transform: translate(1%, 0px);
  right: 1px;
  top: 54px;
}

/* Weather dropdown */
.editdw-weather {
  position: absolute;
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: none;
  font-size: 16px;
  width: 166px;
  border: 1px solid #ccc;
  transform: translate(1%, 0px);
  right: 75px;
  top: 140px;
}

.editdw:before,
.editdw-weather:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background: #fff;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  transform: rotate(45deg);
  top: -8px;
  right: 14px;
}
/* youtube popup */
.youtubetable input {
  border: 1px solid #d5e3ff;
  padding: 10px 20px;
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 10px #cccccc26;
}
.youtubetable input::placeholder {
  font-size: 16px;
}
@media (max-width: 1024px) {
  .mutebtn {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
  .youtubetable tr,
  .youtubetable td {
    display: block;
  }
}

@media (max-width: 767px) {
  .youtubebtnpopup button {
    margin-top: 10px;
  }
}
.videoplayer,
.videoinner {
  max-width: 100% !important;
  max-height: 100% !important;
  /* min-height: 100% !important;
  max-height: 100% !important; */
}

.videoinner-preview{
  width: 960px !important;
  height: 540px !important;
}

.videoplayer {
  border: 5px solid #000;
  border-radius: 5px;
}

img.imagebox.img_w,
.videoinner.img_w {
  width: 162px !important;
  height: 100px !important;
  max-width: 162px !important;
}
/* CSS for full-screen video */
/* CSS for the dialog-like container */
/* CSS for the dialog-like container */
.video-preview {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 85%; */
  /* max-height: 80%;
  min-height: 80%; */
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  z-index: 1000;
  /* overflow: hidden; */
}

.youtube-preview {
  width: 100% !important;
  height: 100% !important;
}
.previewinner {
  width: 100% !important;
  height: 80vh !important;
}
.app-instance-preview {
  width: 100% !important;
  height: 50vh !important;
}

.newScreenDetails {
  max-width: 700px !important;
  min-width: 400px !important;
}

.textWrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.thumbnail-container {
  width: 100%; /* Adjust as needed */
  height: 100%; /* Set a fixed height for the thumbnail */
  overflow: hidden; /* Hides overflow */
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.thumbnail {
  /* max-width: 100%;  */
  /* max-height: 100%;  */
  width: auto; /* Maintain aspect ratio */
  height: auto; /* Maintain aspect ratio */
}

@keyframes bounce2 {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.hithere {
  animation: bounce2 5s linear ;
}

@keyframes hithere {
  30% { transform: scale(1.2); }
  40%, 60% { transform: rotate(-20deg) scale(1.2); }
  50% { transform: rotate(20deg) scale(1.2); }
  70% { transform: rotate(0deg) scale(1.2); }
  100% { transform: scale(1); }
}

@keyframes wobble {
  0% { transform: translateX(0%); }
  15% { transform: translateX(-25%) rotate(-5deg); }
  30% { transform: translateX(20%) rotate(3deg); }
  45% { transform: translateX(-15%) rotate(-3deg); }
  60% { transform: translateX(10%) rotate(2deg); }
  75% { transform: translateX(-5%) rotate(-1deg); }
  100% { transform: translateX(0%); }
}


.flip-clock .flip-clock-seconds {
  display: none;
}

@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.font-digital {
  font-family: 'Press Start 2P', cursive;
  letter-spacing: 2px; /* Adjust spacing for digital look */
}




